<template>
  <div class="Home">
    <div class="hotlist" ref="box">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pull-distance="180" :disabled="refdisabled">
        <van-list v-model="loading" :error.sync="error" :immediate-check="immediatecheck" :finished="finished" error-text="请求失败，点击重新加载" finished-text="没有更多了" @load="onLoad">
          <van-cell class="hotlist-item" v-for="(item,index) in hotList" :key="index">
            <div class="hotlist-info" @click.stop="handleApply(item.linkUrl,item.uuid,item.name)">
              <div class="hotlist-info_basic">
                <div class="hotlist-info_head">
                  <img v-lazy="item.icon" alt="">
                </div>
                <div class="hotlist-info_info">
                  <div class="hotlist-info_title">{{item.name}}</div>
                  <div class="hotlist-info_term">
                    贷款期限{{item.loanTermMin}}~{{item.loanTermMax}}{{item.termUnit=='d'?'日':item.termUnit=='M'?'个月':'年'}}
                  </div>
                </div>
              </div>
              <div class="hotlist-info_message">
                <div class="hotlist-info_quota">
                  <div class="hotlist-info_quotanumber">
                    {{filterStatus(item.limitRangeMin)}}~{{filterStatus(item.limitRangeMax)}}</div>
                  <div class="hotlist-info_unit">额度范围（元）</div>
                </div>
                <div class="hotlist-info_speed">
                  <div class="hotlist-info_loantime">
                    {{item.loanTime}}{{item.timeUnit=='m'?'分钟':item.timeUnit=='h'?'小时':'天'}}放款</div>
                  <div class="hotlist-info_interest">
                    {{item.rateUnit=='d'?'日化':item.rateUnit=='M'?'月化':item.rateUnit=='y'?'年化':''}}费率{{item.loanRate}}%
                  </div>
                </div>
              </div>
            </div>
            <div class="hotlist-btn" @click.stop="handleApply(item.linkUrl,item.uuid,item.name)">
              <span class="hotlist-apply">一键申请</span>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { HANDLEAPI } from '@/api/api' // api

export default {
  name: 'index',
  data () {
    return {
      finished: false,
      immediatecheck: false,
      refdisabled: false,
      loading: false,
      error: false,
      tabData: [
        {
          id: '1',
          imgUrl: require('../../assets/img/identity.png'),
          title: '身份证'
        },
        {
          id: '2',
          imgUrl: require('../../assets/img/salary.png'),
          title: '工薪贷'
        },
        {
          id: '3',
          imgUrl: require('../../assets/img/speed.png'),
          title: '极速贷'
        },

        {
          id: '4',
          imgUrl: require('../../assets/img/credit.png'),
          title: '信用分'
        }
      ],
      page: { currentPage: 1, pageSize: 10, params: { scene: '2' } },
      hotList: [],
      totalPage: 0,
      isLoading: false,
      scrollTop: 0
    }
  },
  watch: {
    scrollTop (val) {
      if (val === 0) {
        this.refdisabled = false
      } else {
        this.refdisabled = true
      }
    }
  },
  mounted () {
    this.$store.commit('showLoading')
    if (window.webkit && !this.$isWeiXin()) {
      setTimeout(() => {
        this.getList()
        const box = this.$refs.box
        box.addEventListener('scroll', () => {
          this.scrollTop = box.scrollTop
        })
      }, 300)
    } else {
      this.getList()
      const box = this.$refs.box
      box.addEventListener('scroll', () => {
        this.scrollTop = box.scrollTop
      })
    }
  },
  methods: {
    // 详情
    handleDetail (id, name, url) {
      if (this.$route.name === 'detail') {
        this.$store.dispatch('Buryingpoint', {
          accessType: 'track',
          pageId: 'sy00002',
          pageName: '一键申请',
          elementId: id,
          elementName: name,
          elementProperties: 'apply'
        })
        if (url && /(http|https):\/\/([\w.]+\/?)\S*/.test(url)) {
          const u = navigator.userAgent
          const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
          if (isiOS) {
            if (/(https):\/\/([\w.]+\/?)\S*/.test(url)) {
              this.$router.push({
                name: 'webview',
                query: {
                  linkUrl: url,
                  name: name
                }
              })
            } else {
              if (window.webkit) {
                window.webkit.messageHandlers.openInNative.postMessage(url)
              }
            }
          } else {
            this.$router.push({
              name: 'webview',
              query: {
                linkUrl: url,
                name: name
              }
            })
          }
        }
      } else {
        this.$store.dispatch('Buryingpoint', {
          accessType: 'track',
          pageId: 'sy00001',
          pageName: '详情',
          elementId: id,
          elementName: name,
          elementProperties: 'detail'
        })
        this.$router.push({
          name: 'detail',
          query: {
            uuid: id
          }
        })
      }
    },
    // 一键申请
    handleApply (url, id, name) {
      this.$store.dispatch('Buryingpoint', {
        accessType: 'track',
        pageId: 'sy00002',
        pageName: '一键申请',
        elementId: id,
        elementName: name,
        elementProperties: 'apply'
      })
      // window.location.href = url
      if (url && /(http|https):\/\/([\w.]+\/?)\S*/.test(url)) {
        const u = navigator.userAgent
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isiOS) {
          if (/(https):\/\/([\w.]+\/?)\S*/.test(url)) {
            this.$router.push({
              name: 'webview',
              query: {
                linkUrl: url,
                name: name
              }
            })
          } else {
            if (window.webkit) {
              window.webkit.messageHandlers.openInNative.postMessage(url)
            }
          }
        } else {
          this.$router.push({
            name: 'webview',
            query: {
              linkUrl: url,
              name: name
            }
          })
        }
      } else {
        window.location.href = url
      }
    },
    /** 过滤 */
    filterStatus (val) {
      return val >= 1000000
        ? val.toString().substr(0, 3) + 'W'
        : val >= 100000
          ? val.toString().substr(0, 2) + 'W'
          : val
    },
    // 下拉刷新
    onRefresh () {
      this.page.currentPage = 1
      this.loading = false
      this.finished = false
      this.getList()
    },
    // 加载更多
    onLoad () {
      this.page.currentPage++
      if (this.totalPage < this.page.currentPage) {
        this.loading = false
        this.finished = true
        return
      }
      this.loading = true
      this.finished = false
      this.getList('add')
    },
    // 获取数据源
    getList (type) {
      HANDLEAPI('surplusesList', this.page)
        .then((res) => {
          this.loading = false
          this.isLoading = false
          this.immediatecheck = true
          this.$store.commit('hideLoading')
          if (res.code === 0) {
            this.$store.dispatch('Buryingpoint', {
              accessType: 'track',
              pageId: 'sy00004',
              pageName: '更多借款页面初始化',
              elementId: '0',
              elementName: '页面初始化',
              elementProperties: 'webinit'
            })
            if (type === 'add') {
              this.hotList = this.hotList.concat(res.data.list)
            } else {
              this.hotList = res.data.list
            }
            this.totalPage = res.data.lastPage
            if (this.page.currentPage >= this.totalPage) {
              this.finished = true
            }
          } else {
            this.error = true
          }
        })
        .catch(() => {
          this.error = true
          this.loading = false
          this.isLoading = false
          this.$store.commit('hideLoading')
        })
    },
    // 筛选
    handleSearch (id) {
      this.$router.push({
        name: 'list',
        query: {
          id: id
        }
      })
    },
    // 更多推荐
    handleMore () {
      this.$router.push({
        name: 'list'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.Home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f3f3f3;
  box-sizing: border-box;
  .hotlist {
    height: 100vh;
    box-sizing: border-box;
    background-color: #f3f3f3;
    overflow: hidden;
    /deep/ .van-pull-refresh {
      height: 100vh !important;
      overflow: auto !important;
    }
    /deep/ .van-pull-refresh::-webkit-scrollbar {
      display: none;
    }
    /deep/ .van-list {
      touch-action: pan-y;
      -webkit-overflow-scrolling: touch;
      height: auto !important;
    }
    .hotlist-item {
      background-color: #fff;
      margin: 0.02rem 0;
      padding: 0.2rem 0.3rem;
      /deep/.van-cell__value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .hotlist-info {
          width: 70%;
          .hotlist-info_basic {
            display: flex;
            justify-content: flex-start;
            .hotlist-info_head {
              img {
                width: 0.75rem;
                height: 0.75rem;
              }
            }
            .hotlist-info_info {
              padding-left: 0.15rem;
              .hotlist-info_title {
                color: #111;
                font-size: 0.34rem;
                line-height: 0.42rem;
                text-align: left;
              }
              .hotlist-info_term {
                color: #888;
                font-size: 0.22rem;
                line-height: 0.36rem;
              }
            }
          }
          .hotlist-info_message {
            display: flex;
            justify-content: space-between;
            .hotlist-info_quota {
              width: 45%;
              text-align: left;
              border-right: 1px solid #e4e4e4;
              margin: 0.1rem 0;
              .hotlist-info_quotanumber {
                color: #ff4846;
                font-size: 0.3rem;
                line-height: 0.44rem;
              }
              .hotlist-info_unit {
                color: #888;
                font-size: 0.24rem;
              }
            }
            .hotlist-info_speed {
              width: 55%;
              text-align: left;
              padding-left: 0.2rem;
              .hotlist-info_interest,
              .hotlist-info_loantime {
                color: #888;
                font-size: 0.24rem;
                line-height: 0.32rem;
                padding-left: 0.2rem;
              }
              .hotlist-info_loantime {
                padding-top: 0.1rem;
                line-height: 0.5rem;
              }
            }
          }
        }
      }

      .hotlist-btn {
        width: 30%;
        text-align: right;
        line-height: 1.5rem;
        .hotlist-apply {
          color: #ff9d20;
          font-size: 0.26rem;
          line-height: 0.33rem;
          border: 1px solid #ff9d20;
          padding: 0.12rem 0.28rem;
          border-radius: 0.08rem;
        }
      }
    }
    .hotlist-item:last-child {
      margin-bottom: 0;
    }
    .van-cell::after {
      border: none;
    }
  }
}
</style>
